import React, { useEffect } from 'react'

import { navigate } from 'gatsby-link'

import Loader from 'components/shared/loader'
import useAuth from 'context/auth/use'

const AuthCallback = () => {
  const {
    isAuthenticated,
    isAccountExecutive,
    isAdmin,
    isArtist,
    isBuildZoom,
    isCustomerSupport,
    isRenderManager,
    isSalesDesigner,
    isSelectionCenterManager,
    isTechDesigner,
    isTechDesignerSupport,
    isTradeDesigner,
    isLoading,
    error,
    isNobiliaOrderProcessor,
  } = useAuth()
  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      if (isSelectionCenterManager) {
        navigate('/selection-centers/admin')
      } else if (
        isAccountExecutive ||
        isAdmin ||
        isArtist ||
        isBuildZoom ||
        isCustomerSupport ||
        isNobiliaOrderProcessor ||
        isRenderManager ||
        isSalesDesigner ||
        isTechDesigner ||
        isTechDesignerSupport ||
        isTradeDesigner
      ) {
        navigate('/admin')
      } else {
        navigate('/app')
      }
    }
  }, [isLoading, isAuthenticated])

  useEffect(() => {
    if (error) {
      console.warn(`Error fetching auth0 data`, error)
    }
  }, [error])

  return <Loader />
}

export default AuthCallback
